import { createAsyncThunk } from '@reduxjs/toolkit';

import { PaginatedData, AnnotatedTruckReport, PagePrams } from '@shared/types';
import { request } from '@shared/api';
import { queryStringify } from '@shared/utils';
import { TruckReportFormValues } from '@components/pages/reporting/daily-truck-report/partials/types';

export const getTruckReports = createAsyncThunk(
  'truckReports/getTruckReports',
  async ({ page, filter }: { page: PagePrams; filter?: Record<string, unknown> }) => {
    return await request<PaginatedData<AnnotatedTruckReport>>(
      'get',
      `truck_reports?${queryStringify({
        page,
        filter
      })}`
    );
  }
);

const uploadTruckReportPhotos = (
  truckReport: TruckReportFormValues,
  updatedTruckReport: AnnotatedTruckReport
) => {
  const formData = new FormData();

  formData.append('truck_report[id]', (updatedTruckReport?.id ?? 0).toString());

  truckReport.photos.forEach(photo => {
    formData.append('truck_report[photos][]', photo);
  });

  return request<AnnotatedTruckReport, FormData>(
    'put',
    `truck_reports/${updatedTruckReport.id}`,
    formData
  );
};

export const createTruckReport = createAsyncThunk(
  'truckReports/createTruckReport',
  async (truckReportForm: TruckReportFormValues) => {
    const newTruckReport = await request<
      AnnotatedTruckReport,
      { truckReport: TruckReportFormValues }
    >('post', 'truck_reports', {
      truckReport: truckReportForm
    });
    return uploadTruckReportPhotos(truckReportForm, newTruckReport);
  }
);

export const updateTruckReport = createAsyncThunk(
  'truckReports/updateTruckReport',
  async (truckReportForm: TruckReportFormValues) => {
    const updatedTruckReport = await request<
      AnnotatedTruckReport,
      { truckReport: TruckReportFormValues }
    >('patch', `truck_reports/${truckReportForm.id}`, {
      truckReport: truckReportForm
    });
    return uploadTruckReportPhotos(truckReportForm, updatedTruckReport);
  }
);

export const deleteTruckReport = createAsyncThunk(
  'truckReports/deleteTruckReport',
  async (truckReport: AnnotatedTruckReport) => {
    return await request<unknown, { truckReport: AnnotatedTruckReport }>(
      'delete',
      `truck_reports/${truckReport.id}`
    );
  }
);
